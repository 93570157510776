import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useCurrentUser } from "../context/CurrentUserProvider";
import { UsersContext } from "../context/UsersProvider";
import styles from "./HomePage.module.css";
import Modal from "../components/Modal";
import Reminders from "../components/Reminders";
import StudentsResources from "../components/Students/StudentsResources";
import UpdateProfileForm from "../components/Users/UpdateProfileForm";
import AnnouncementHomeComp from "../components/Announcements/AnnouncementHomeComp";
import HomeCardComponent from "../components/Card/HomeCardComponent";
import ImportantInfoComponent from "../components/ImportantInfoHome/ImportantInfoComponent";
import ActionsComponent from "../components/Home/ActionsComponent";
import HomeCardComponentModal from "../components/Card/HomeCardModalComponent";
import ConfidentialityAgreementModal from "../components/Home/ConfidentialityAgreementModal";
import InnovativeTeacherModal from "../components/Home/InnovativeTeacherModal";
import InnovativeTeacherModalAward from "../components/Home/InnovativeTeacherModalAward";
import MembershipModal from "../components/Home/MembershipModal";
const HomePage = () => {
  const { currentUser } = useCurrentUser();
  const { userAct, showModalMembership, setShowModalMembership } = useContext(UsersContext);
  const [updateUserInfo, setUpdateUserInfo] = useState(false);
  const [openModalAgreement, setOpenModalAgreement] = useState(false);
  const [openInnovative, setOpenInnovative] = useState(false);
  const [openInnovativeAward, setOpenInnovativeAward] = useState(false);
  const [openMembership, setOpenMembership] = useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    // showModalMemberhsip guardado en el localstorage
    if (currentUser?.branch!=="VES" || !showModalMembership) {
      setOpenMembership(false);
    }
  },[currentUser,showModalMembership]);
  useEffect(() => {
    const targetDate = new Date('2024-08-27');
    const lastUpdate = new Date(userAct?.updated_at);

    if (lastUpdate < targetDate) {
      setUpdateUserInfo(true);
    }
  }, [userAct]);

  const date = new Date();
  const options = {
    weekday: "long",
    month: "long",
    day: "numeric",
  };

  const formattedDate = date.toLocaleString("es-ES", options);

  let hasMaterials;

  currentUser?.institution?.forEach((inst) => {
    if (inst.materials) {
      hasMaterials = true;
    }
  });
  const isTeacher =
  currentUser.role === "external_teacher" ||
  currentUser.role === "internal_teacher" ||
  currentUser.role === "development_teacher" ||
  currentUser.role === "external_coordinator";

const rolesCalendar = 
currentUser.role !== "academic_coordination" &&
currentUser.role !== "academic_director" && 
currentUser.role !== "external_coordinator" && 
currentUser.role !== "external_teacher" && 
currentUser.role !== "internal_teacher" && 
currentUser.role !== "development_teacher" &&
currentUser.role !== "purchases_and_inventory";

  return (
    <div className={styles.main}>
      <section className={styles.leftside}>
        <div className={styles.opening}>
          <div className={styles.userinfo}>
            {currentUser && (
              <h2
                className={styles.name}
              >{`Bienvenido, ${currentUser["first_name"]}`}</h2>
            )}
            <p className={styles.date}>{formattedDate}</p>
          </div>
          <div className={styles.logos}>
            <img alt='institution'
              src={
                (currentUser?.institution &&
                  currentUser?.institution[0]?.logo) ||
                "IsotipoK.png"
              }
              width={70}
            />
          </div>
        </div>
        {currentUser.role !== "student" ? (
          <>
           <AnnouncementHomeComp />
            {/*{hasMaterials && (
              <Box>
                <h1 style={{margin: '10px 0'}}>Recursos</h1>
                <Box sx={{ maxHeight: '140px', overflowY: 'auto' }}>
                <Stack direction={"column"} spacing={1}>
                  {currentUser?.institution?.map((inst, index) => {
                    if (inst?.materials) {
                      return (
                        <HomeCardComponent key={index}
                        title={`Inventario general - ${inst?.name}`}
                        link={inst?.materials} color={'var(--sec-blue)'} tab={true} />
                      );
                    }
                  })}
                </Stack>
                </Box>
              </Box>
            )}*/} 
            {/* REGISTRO FOTOGRAFICO Y CARGA DE HORARIOS */}
            {isTeacher && (
              <ActionsComponent />
            )}      
            <Box>
              <h1 style={{margin: '10px 0'}}>Capacitaciones del día</h1>
              <Box sx={{ maxHeight: '140px', overflowY: 'auto' }}>
              <Stack direction={"column"} spacing={1}>
                <HomeCardComponent 
                title={`Lista de Capacitaciones del día`}
                handleClick={() => navigate('/training-overview')} color={'var(--sec-blue)'} />
              </Stack>
              </Box>
            </Box>
            <Box>
            <h1 style={{margin: '10px 0'}}>Capacitaciones</h1>
            <Box sx={{ maxHeight: '18hv', overflowY: 'auto' }}>
            <Stack direction={"column"} spacing={1}>
              <HomeCardComponent 
              title={`Capacitaciones`}
              handleClick={() => navigate('/capacitaciones/list')} color={'var(--sec-purple)'} />
            </Stack>
            {currentUser?.branch === 'VES' && (
              <>
              
               <Stack sx={{ marginTop: '10px' }} direction={"column"} spacing={1}>
               <HomeCardComponentModal
                 title="Acuerdo de Confidencialidad"
                 color="var(--sec-yellow)"
                 setOpenAgreement={setOpenModalAgreement}
               />
             </Stack>
             
              {openModalAgreement && (
                <ConfidentialityAgreementModal setOpenAgreement={setOpenModalAgreement} />
              )}
               <Box>
                 <h1 style={{margin: '10px 0'}}>Docente Innovador</h1>
                <Box sx={{ maxHeight: '140px', overflowY: 'auto' }}>
                  <Stack direction={"column"} spacing={1}>
                  {/* <HomeCardComponent title={`Docente Innovador`} color={'var(--sec-green)'}
                    handleClick={() => setOpenInnovative(true)}/> */}
                     <HomeCardComponent title={`Docente Innovador`} color={'var(--sec-green)'}
                    handleClick={() => setOpenInnovativeAward(true)}/>
                  </Stack>
                </Box>
              </Box>
              {/* {openInnovative && <InnovativeTeacherModal setOpenInnovative={setOpenInnovative} />} */}
              {openInnovativeAward && <InnovativeTeacherModalAward setOpenInnovativeAward={setOpenInnovativeAward} />}
              </>
            )}
            </Box>
            </Box>
            {showModalMembership && <MembershipModal openMembership={openMembership} setOpenMembership={setOpenMembership} />}

            {/*<ImportantInfoComponent />*/}
            {updateUserInfo && (
                  <Modal
                    title={"Confirmar Información Personal"}
                    modalOpen={setUpdateUserInfo}
                  >
                    <div style={{ height: "100%", overflowY: "scroll" }}>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          padding: "10px 10px 0px 10px",
                        }}
                      >
                        Por favor confirmar los siguientes datos personales:
                      </p>
                      <UpdateProfileForm setUpdateUserInfo={setUpdateUserInfo} />
                    </div>
                  </Modal>
            )}
          </>
        ) : (
          <StudentsResources />
        )}
      </section>
      {currentUser.role !== "student" && <Reminders />}
    </div>
  );
};

export default HomePage;