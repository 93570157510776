import React, {useContext, useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import DataTable from "react-data-table-component";
import { CoursesContext } from "../../context/CoursesContext";
import styles from './InstitutionCourses.module.css'
import Container from '../Container';
import { INSTITUTIONS_ENDPOINT } from '../../utils/constants';
import { useLoading } from '../../context/LoadingContext';
import LoadingComponent from '../LoadingComponent';
import { get } from '../../utils/http';
import { resetWarningCache } from 'prop-types';


const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#000",
        fontSize: "13px",
        fontWeight: "bold",
        borderBottom: "1px solid #E0E0E0",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#f5f5f5",
        borderBottomColor: "#FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
};

function InstitutionCourses({institution, currentUser}) {
    const {setSelectedCourse, courses } = useContext(CoursesContext);
    const [filterCourse, setFilterCourse] = useState("");
    const [courses2, setCourses2] = useState([])
    const { showLoading, hideLoading, isLoading } = useLoading();

    const navigate = useNavigate()

    const fetchCourses = async () => {
      showLoading()
      const url = `${INSTITUTIONS_ENDPOINT}${institution.id}/courses/`;
      const response = await get({ url });
      setCourses2(response.data); 
      hideLoading()
    };

    useEffect(() => {
      fetchCourses();
    }, [institution]);

    let finalCourses

    if(currentUser?.role === "external_coordinator"){
      finalCourses = courses2?.filter((item) => 
      courses?.some((item2) => item2?.id === item?.id))
    }else{
      finalCourses = courses2
    }


    let filteredCourses = courses2 && courses2?.filter((item) =>
      item?.program?.toLowerCase()?.includes(filterCourse.toLowerCase())
    );

    const handleChangeFilterCourses = (e) => {
      setFilterCourse(e.target.value);
    };

    const handleCourseClick = (course) => {
      setSelectedCourse(course);
      navigate(`/courses/${course.id}`)
    };

    const columns = [
      {
        name: "Programa",
        selector: (row) => row?.program,
        sortable: true,
        cell: (cell) => (
          <div>{cell.program}</div>
        )
      },
      {
        name: "Grado",
        selector: (row) => row?.institution_level,
        sortable: true,
      },
      {
        name: "Completado",
        selector: (row) => {
          let result; 
          if (row?.program_versions?.length > 1) 
            { 
              result = (row?.lesson_count/ row?.program_versions?.length); 
            } else 
            {
               result =  row?.lesson_count; 
            } 
          return `${row?.average_completed}/${result}`
        },
        sortable: true,
      },
      {
        name: "Docente",
        selector: (row) => row.teachers,
        sortable: true,
        cell: (cell) => {
          const teachers = []
          institution?.teachers?.map((teacher)=>{
            if(cell.teachers.includes(teacher.id)){
              teachers.push(teacher)
            }
          })

          return(
            <div>
              {teachers?.map((teacher, i) => (
                <p key={i}>{`${teacher?.info?.first_name} ${teacher?.info?.last_name}`}</p>
              ))}

            </div>
          )
        }
      },
      {
        name: "",
        selector: (row) => row?.lesson_count,
        cell: (cell) => {
          if(cell?.unread_forum){
            return(
              <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
              <i class="fas fa-exclamation-circle" style={{color: "var(--sec-yellow)"}}></i>
                <p style={{color: "var(--sec-yellow)", fontSize:'10px',textAlign: 'center', fontWeight: 'bold'}}>
                  Nuevo mensaje foro
                </p>
              </div>
            )
          }
        }
      },
  ];
  return (
    <Container direction={"column"}>
        <div className={styles["search-bar"]}>
          <input
            id="search"
            type="text"
            placeholder="Buscar por programa"
            aria-label="Search Input"
            value={filterCourse}
            onChange={(e) => handleChangeFilterCourses(e)}
            className={styles.filter_text}
          />
        </div>
        <div className={styles.data_table}>
          <DataTable
            columns={columns}
            data={filteredCourses}
            customStyles={customStyles}
            sortIcon={
              <i
                className="far fa-arrow-up"
                style={{ marginLeft: "5px", fontSize: "14px" }}
              ></i>
            }
            highlightOnHover
            pointerOnHover
            fixedHeader
            fixedHeaderScrollHeight="500px"
            onRowClicked={(row) => handleCourseClick(row)}
          />
        </div>
        {isLoading && <LoadingComponent />}
    </Container>
  )
}

export default InstitutionCourses