import React, {useContext, useState, useEffect} from 'react'
import DataTable from "react-data-table-component";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import {  TEACHERS_ENDPOINT, FORM_TEMPLATE_ENDPOINT, INSTITUTIONS_ENDPOINT } from '../../../utils/constants';
import { get } from '../../../utils/http';
import { FormsContext } from '../../../context/FormsContext';
import styles  from "./SurveyAnswers.module.css"
import { wordCapitalize } from '../../../utils/capitalize';
import { dateFormatter } from '../../../utils/dateFormatter';
import UserAnswersModal from './UserAnswersModal';
import { handleDownloadExcel } from '../../../utils/downloadToExcel';

const chartOptions = {
  plugins: {
    legend: {
      position: 'left',
      align: 'end',
      labels: {
        boxWidth: 20, // Ajusta el tamaño del cuadrito del color de la leyenda
      },
    },
  },
};  
const colors = [
  "#685bc7",
  "#FF6384", 
  '#36A2EB', 
  "#43b02a",
  '#FFCE56',
  "#1b9e77",
  "#d95f02",
  "#7570b3",
  "#e7298a",
  "#66a61e",
  "#e6ab02",
  "#a6761d",
  "#4E79A7",
  "#A0CBE8",
  "#F28E2B",
  "#FFBE7D",
  "#59A14F",
  "#8CD17D",
  "#B6992D",
  "#F1CE63",
  "#499894",
  "#86BCB6",
  "#E15759",
  "#FF9D9A",
  "#79706E",
  "#BAB0AC",
  "#D37295",
  "#FABFD2",
  "#B07AA1",
  "#D4A6C8",
  "#9D7660",
  "#D7B5A6",
]
const customStyles = {
  headRow: {
    style: {
      border: "none",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "13px",
      fontWeight: "bold",
      borderBottom: "1px solid #E0E0E0",
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: "#f5f5f5",
      borderBottomColor: "#FFFFFF",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
};
const columns = [
  {
    name: "Docente",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Intitución",
    selector: (row) => row.institution,
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.answer_at,
    sortable: true,
    cell: (cell) => {
      let status
      if(cell.answer_at){
        status = "Completado"
      }else{
        status = "Por completar"
      }
      
      return (
        <div
          style={{
            backgroundColor:  status === "Por completar" ? "#FFCC02" : "#22B532",
            padding: "0.8rem",
            borderRadius: "20px",
          }}
        >
          {status}
        </div>
      );
    },
  },
  {
    name: "Fecha de respuesta",
    selector: (row) => row.answer_at,
    sortable: true,
    cell: (cell) => {
      const dat = cell.answer_at ? dateFormatter(cell?.answer_at) : ""
      
      return (
        <div>
          {dat}
        </div>
      );
    },
  },
];

function SurveyAnswers() {
  const [formsAnswers, setFormsAnswers] =useState([])
  const [teachers, setTeachers] = useState([]);
  const [teachersInfo, setTeachersInfo] = useState([]);
  const {selectedFormTemplate} =useContext(FormsContext)
  const [institutions, setInstitutions] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedUserName, setSelectedUserName] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [surveyInfo, setSurveyInfo] = useState([])
  const [exportData, setExportData] = useState([])

  useEffect(() => {
    const fetchTeachers = async () => {
      const url = TEACHERS_ENDPOINT;
      const response = await get({ url });

      setTeachers(response.data);
    };
    fetchTeachers();
  }, []);

  useEffect(() => {
    const fetchInstitutions = async () => {
      const url = INSTITUTIONS_ENDPOINT;
      const response = await get({ url });

      setInstitutions(response.data);
    };
    fetchInstitutions();
  }, []);

  useEffect(() => {
    const fetchAnswersForm = async () => {
      const url = `${FORM_TEMPLATE_ENDPOINT}${selectedFormTemplate?.id}/answers/`;
      const response = await get({ url });
      setFormsAnswers(response.data);
    };
    fetchAnswersForm();
  }, []);

  useEffect(() => {
    
    const info =[]

    selectedFormTemplate?.questions.forEach((data) => {
    const question = data.question
    const dat = {
      id: question.id,
      question: question.description,
      question_type: question.options_type,
      answers: []
    }
    if(!info?.includes(question.description)){
      info?.push(dat)
    }
    })

    formsAnswers?.forEach((answerData) => {
    answerData?.answers.map((answer) => {
      info?.forEach((data) => {
        if(answer.question === data.id){
          const ans = {
            id: answer.id,
            teacher_id: answerData.user,
            answer: answer.content,
            institution: answerData.institution
          }
          data?.answers?.push(ans)
        }
      })
    })
    })
    setSurveyInfo(info)
  }, [selectedFormTemplate, formsAnswers]);

  useEffect(() => {
    const teachersForm = []
    formsAnswers?.forEach((form) => {
      teachers?.forEach((teacher) => {
        if(teacher?.info?.id === form?.user){
          institutions?.forEach((inst) => {
            if(form?.institution === inst?.id){
              const teacher_info ={
                userId: teacher.info.id,
                name: `${teacher.info.first_name} ${teacher.info.last_name}`,
                institution: inst.name,
                institutionId: inst.id,
                answer_at: form.completed_date
              }
              teachersForm?.push(teacher_info)
            }
          })
        }
      })
    })
    setTeachersInfo(teachersForm)
  }, [formsAnswers, teachers, institutions]);

  const surveyAnswered = teachersInfo?.filter(item => item.answer_at !== null).length;

  teachersInfo?.sort((a, b) => {
    // Primero, comprobar si alguno de los elementos tiene 'answer_at' como null
    if (a.answer_at === null && b.answer_at !== null) {
      return 1;
    } else if (a.answer_at !== null && b.answer_at === null) {
      return -1;
    }
  
    // Si ambos tienen 'answer_at' no nulos o ambos son nulos, ordena alfabéticamente por 'name'
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    }
  
    return 0;
  });

  const handleOpenUserAnswers = (row) => {
    const userAnswers = []
    formsAnswers.forEach((answer) => {
      if(answer.user === row.userId){
        userAnswers?.push(answer)
      }
    })
    setSelectedUser(userAnswers)
    if(row.answer_at){
      const userData = {
        userName: row.name,
        institutionId: row.institutionId,
        institution: row.institution
      }
      setSelectedUserName(userData)
      setOpenModal(true)
    }
  }

  const teacherInfoMap = teachersInfo.reduce((acc, teacher) => {
    acc[teacher.userId] = {
      name: teacher.name,
      institution: teacher.institution 
    };
    return acc;
  }, {});

  const questionIdToTitle = selectedFormTemplate?.questions?.reduce((acc, question) => {
    acc[question.question.id] = question.question.description;
    return acc;
  }, {});

  const filteredFormsAnswers = formsAnswers?.filter(formAnswer => formAnswer?.completed_date);
  useEffect(() => {
    if(filteredFormsAnswers.length > 0 && exportData.length === 0){
      const rows = filteredFormsAnswers?.map(formAnswer => {
        const teacher = teacherInfoMap[formAnswer.user];
        const row = {
          TeacherName: teacher?.name,
          Institution: teacher?.institution,
          CompletedDate: dateFormatter(new Date(formAnswer?.completed_date)) 
        };
      
        formAnswer?.answers?.forEach(answer => {
          const questionTitle = questionIdToTitle[answer.question];
          row[questionTitle] = answer?.content;
        });
      
        return row;
      });
      if(rows){
        setExportData(rows)
        
      }
  }

    
  }, [exportData]);
  return (
    <div className={styles.full_container}>
      <button
          onClick={() =>
            handleDownloadExcel(exportData, selectedFormTemplate?.name)
          }
          className={styles.download}
        >
          Descargar Respuestas
        </button>
    <div className={styles.container}>
      {surveyInfo?.map((data) => {
        let selectM = false
        let selectS = false
        let dataCountsM = {};
        let dataCountsS = {};
        if(data.question_type !== null && data.question_type !== 0){
          if(data?.answers[0]?.answer?.includes("[")){
            selectM = true
            data?.answers?.forEach((item) => {
              const letters = JSON.parse(item.answer.replaceAll("'", '"'));
              letters.forEach((letter) => {
                if (dataCountsM[letter]) {
                  dataCountsM[letter] += 1;
                } else {
                  dataCountsM[letter] = 1;
                }
              });
            });
          }else{
            selectS= true
            data?.answers?.forEach((item) => {
              if (dataCountsS[item.answer]) {
                dataCountsS[item.answer] += 1;
              } else {
                dataCountsS[item.answer] = 1;
              }
            });
          }
        }
        let chartData
        if(selectS){
          chartData = {
            labels: Object.keys(dataCountsS),
            datasets: [
              {
                data: Object.values(dataCountsS),
                backgroundColor: colors
              },
            ],
          };
        }else if (selectM){
          chartData = {
            labels: Object.keys(dataCountsM),
            datasets: [
              {
                data: Object.values(dataCountsM),
                backgroundColor: colors
              },
            ],
          };
        }
        return(
          <div className={styles.content}>
            <>
            {data.answers.length > 0 && 
            <>
            {(data.question_type !== null && data.question_type !== 0) ?
            (
              <div className={styles.question_answer}>
                <p key={data.id}>{wordCapitalize(data.question)}</p>
                {chartData && 
                  <div style={{width: '260px', height: "230px"}}>
                    <Chart type='doughnut' data={chartData} options={chartOptions} />
                  </div>
                }
              </div>
              ) : (
              <div className={styles.question_answer_text}>
                <p key={data.id}>{wordCapitalize(data.question)}</p>
                <div className={styles.answers_component}>
                  {data.answers.map((answ) => {
                    let teacher_name 
                    let institution_name
                    teachers?.forEach((teacher) => {
                      if(answ.teacher_id === teacher.info.id){
                        teacher_name = `${teacher.info.first_name} ${teacher.info.last_name}`
                      }
                    })
                    institutions?.forEach((insti) => {
                      if(answ.institution === insti.id){
                        institution_name = `${insti.name}`
                      }
                    })
                    return(
                    <div className={styles.answer_content} key={answ.id}>
                      <p className={styles.answer}>{answ.answer}</p>
                      <p className={styles.teacher}>{`${teacher_name} - ${institution_name}`}</p>
                    </div>)
                  })}
                </div>
              </div>
            )}
            </>
            }</>
          </div>
        )
      })}
    </div>
    <div className={styles.table_container}>
      <p style={{fontSize: '18px', fontWeight: 'bold', marginTop: '1rem', marginBottom: '1rem'}}>Encuestas por docente <span style={{fontWeight: 'normal', marginLeft: '10px'}}>{surveyAnswered}/{teachersInfo.length}</span></p>
      <DataTable
        columns={columns}
        data={teachersInfo}
        customStyles={customStyles}
        sortIcon={
          <i
            className="far fa-arrow-up"
            style={{ marginLeft: "5px", fontSize: "14px" }}
          ></i>
        }
        highlightOnHover
        pointerOnHover
        fixedHeader
        fixedHeaderScrollHeight="500px"
        onRowClicked={(row) => handleOpenUserAnswers(row)}
      />
    </div>
    {openModal &&
    <UserAnswersModal 
    selectedUser={selectedUser[0]}
    setOpenModal={setOpenModal}
    questions={selectedFormTemplate?.questions}
    selectedUserName={selectedUserName} />
    }
    </div>
  )
}

export default SurveyAnswers