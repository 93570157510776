import { createContext, useState, useEffect, useContext } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { get, post, patch, deleted } from "../utils/http";
import { timestampFormatter } from "../utils/timestampFormatter";
import {
  USERS_ENDPOINT,
  REGISTER_ENDPOINT,
  USERS_FILE_REGISTER_ENDPOINT,
} from "../utils/constants";
import { CurrentUserContext } from "./CurrentUserProvider";
import { useNavigate } from "react-router-dom";
import { AlertsContext } from "./AlertsContext";

const UsersContext = createContext();

const UsersProvider = ({ children }) => {
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [userAct, setUserAct] = useState(null)
  const [selectedUser, setSelectedUser] = useLocalStorage("selectedUser", "");
  const [showModalMembership, setShowModalMembership] = useState(() => {
    return JSON.parse(localStorage.getItem("showModalMembership")) ?? true;
  });

  useEffect(() => {
    // Guardar en el localStorage cada vez que showModalMembership cambia
    localStorage.setItem('showModalMembership', JSON.stringify(showModalMembership));
  }, [showModalMembership])
  const showSnackbar = useContext(AlertsContext);

  const navigate = useNavigate();

  const url = USERS_ENDPOINT;

  // Unique username, password match checks are done on backend. If the registration request is
  // successful, then the user is redirected to a login page.

  const registerUser = async ({ username, password, password2 }) => {
    const url = REGISTER_ENDPOINT;
    const data = { username, password, password2 };

    const response = await post({ url, data });

    if (!response.error) {
      navigate("/users");
      fetchUsers();
    } else {
      alert("Something went wrong!");
    }
  };

  const registerMultipleUsers = async ({ formData }) => {
    const url = USERS_FILE_REGISTER_ENDPOINT;

    const response = await post({ url, data: formData });

    if (!response.error) {
      fetchUsers();
      navigate(-1);
    } else {
    }
  };

  const fetchUsers = async () => {
    const response = await get({ url });
    if (response.error) {
      //setCurrentUser(null);
    } else if (response.data) {
      const users = response.data;

      let usersData = users?.map((user) => {
        // Obtener la información para la tabla de usuarios
        const display_name = `${user.first_name} ${user.last_name}`;
        const user_last_login = new Date(user.last_login).getTime(); //convertir el last login a tiempo

        const totalTimeDiff = timestampFormatter(user_last_login);

        const userData = {
          ...user,

          display_name: display_name,
          last_login: totalTimeDiff,
        };
        return userData;
      });

      setUsers(usersData);

      return usersData;
    }
  };

  const fetchSingleUser = async (userId = currentUser.id) => {
    const response = await get({ url: `${url}${userId}/` });
    if (!response.error) {
      setUserAct(response.data)
      setShowModalMembership(true);
      return response.data;
    } else {
      return response.error;
    }
  };

  const getTeachers = (teacherIds) =>
    users.filter((user) => teacherIds?.includes(user.teacher_profile));

  const updateUser = async ({ userId, data }) => {
    const url = `${USERS_ENDPOINT}${userId}/`;
    //const data = { ...values };

    const response = await patch({ url, data });

    if (!response.error) {
      showSnackbar("Se ha editado el usuario", "success");
      fetchSingleUser(userId)
      fetchUsers();
    }else{
      showSnackbar("Hubo un error al editar al usuario", "error");
    }

    return response;
  };

  const deleteUser = async (userId) => {
    const url = `${USERS_ENDPOINT}${userId}/`;
    const response = await deleted({ url });

    if (!response.error) {
      fetchUsers();
      navigate("/users");
      setSelectedUser(currentUser);
    }
  };

  // Whenever users & state of loading changes. Users state changes

  useEffect(() => {
    // setLoading(false);
    fetchSingleUser()
  }, [currentUser]);

  const contextData = {
    users,
    registerUser,
    registerMultipleUsers,
    fetchUsers,
    fetchSingleUser,
    updateUser,
    selectedUser,
    setSelectedUser,
    deleteUser,
    getTeachers,
    userAct,
    setUserAct,
    showModalMembership,
    setShowModalMembership
  };

  return (
    <UsersContext.Provider value={contextData}>
      {loading ? null : children}
    </UsersContext.Provider>
  );
};

const useUsers = () => {
  return useContext(UsersContext);
};

export { UsersContext, UsersProvider, useUsers };
