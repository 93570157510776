import React from 'react'
import Modal from '../../Modal'

function ComplementaryActivitiesModal({setShowComplementaryActivities, activeLesson}) {
    return (
      <Modal width={'60%'}
      modalOpen={setShowComplementaryActivities} 
      title={"Actividad Complementaria"}>
          <div
          style={{
            width: "100%",
            maxHeight: "80vh",
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
          >
            <>
            {(activeLesson?.complementary_activities?.length > 0 || activeLesson?.complementary_activities!==null) && (

              <p>{activeLesson?.complementary_activities}</p>
              ) || (
                <p style={{textAlign: 'center', padding: '10px 0'}}>
                Esta clase no tiene actividades complementarias
              </p>
              )
              
            }
            </>   
          </div>
      </Modal>
    )
  }
  export default ComplementaryActivitiesModal
  