import React, { useState, useEffect, useRef, useContext } from 'react';
import Modal from '../Modal';
import Slider from "react-slick";
import Skeleton from '@mui/material/Skeleton';
import './InnovativeTeacherModalAward.css';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

const photos = [
    {   
        id: 1,
        url: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/1+Anuncio.png',
    },
    {
        id: 2,
        url: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/2+TOP+3.png',
    },
    {
        id: 3,
        url: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/3+Menci%C3%B3n+especial.png',
    }
]


const InnovativeTeacherModalAward = ({ setOpenInnovativeAward }) => {
    const [loading, setLoading] = useState(true);
    const [showAnimation, setShowAnimation] = useState(true);
  const handleCancel = () => {
    setOpenInnovativeAward(false);
  };
  const settings = {
    className: "center",
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2500,
    cssEase: "ease-out",
    autoplaySpeed: 4000,
    pauseOnHover: true,
    centerMode: true,
  };
  const handleImageLoad = () => {
    setLoading(false);
  };

  return (     
    <Modal title={"Docente Innovador"} modalOpen={setOpenInnovativeAward}
     showAnimation={showAnimation}>
        <div className="slide-container">
            {loading && (
                 <Skeleton
                 variant="rectangular"
                 width={400}
                 height={600}
                 style={{ alignItems: "center", borderRadius: "10px",
                  margin: '0 auto' , display: "flex", justifyContent: "center"
                  }}
                 animation="wave"
               />
                
            )}
            <Slider {...settings}>
                {photos.map((photo) => (
                    <div key={photo.id} className='image-container' >
                        <img src={photo.url} alt={`Photo ${photo.id}`}
                            className={loading ? 'hidden' : 'visible'}
                            onLoad={handleImageLoad}   
                        />
                    </div>
                ))}
            </Slider>
        </div>
    </Modal>
    );
    };

export default InnovativeTeacherModalAward;
