import React from "react";
import { Link } from "react-router-dom";
import styles from "./Card.module.css";

const Card = ({
  courseId,
  currentOption,
  type,
  sections,
  setCurrentOption,
  data,
  average_completed,
  url,
  onClick,
  children,
  nextLesson,
  currentUser
}) => {
  if (type === "course") {
    const title = `${data?.institution_level} - ${data?.program}`;
    let total;

    const programversions = data?.program_versions.length; 
    let bar_perc;
    if (data?.program_versions.length>1) {
        total = data?.lesson_count / data?.program_versions.length;
        bar_perc =
        (data?.average_completed * 100) / ((data?.lesson_count)/data?.program_versions.length);   
    }
    else {
      total = data?.lesson_count;
      bar_perc =
      (data?.average_completed * 100) / data?.lesson_count;
    }
    
      // (data?.next_lesson?.lesson_number * 100) / data?.lesson_count;
    return (
      <div className={styles.card}>
        <div className={styles.logos}>
          <img src={data?.institution?.logo} alt="logo"></img>
        </div>
        <div className={styles.courseData}>
          <div className={styles["first-block"]}>
          <div className={styles.info}>
            <div className={styles.title}>{title}</div>
            {data?.next_lesson?.lesson_number && (
              <div className={styles.small}>
                <i className="fas fa-chevron-right"></i>
                <span>{`Clase ${data?.next_lesson?.lesson_number || 0}: ${
                  data?.next_lesson?.lesson?.title || ""
                }`}</span>
              </div>
            )}
          </div>
          </div>
          <div className={styles["mid-block"]}>
          {data.unread_forum && (
            <div className={styles.notifications}>
              <i className="far fa-exclamation-circle"></i>
              <p>Información nueva</p>
            </div>
          )}
          </div>
          <div className={styles["last-block"]}>
            <div className={styles.lessons}>{`${
            data?.average_completed || 1
            }/${total || 0}`}</div>
              <div className={styles.progressbar}>
                <div
                style={{
                  backgroundColor: "var(--sec-green)",
                  borderRadius: "var(--regular-border-radius)",
                  width: `${bar_perc || 0}%`,
                  height: "100%",
                }}
                ></div>
              </div>
          </div>
        </div>
      </div>
    );
  } else if (type === "course-header") {
    let bar_perc;
    let division;
    if (data?.programversionslength>1) {
        division = data?.lessonCount/data?.programversionslength;
        bar_perc =
        (data?.average_completed * 100) / (division); 
    }
    else {
      division = data?.lessonCount;
      bar_perc =
      (data?.average_completed * 100) / data?.lessonCount;
    }
    return (
      <div className={styles.card_header}>
        {(currentUser.role !== "external_teacher" && currentUser.role !== "internal_teacher" 
        && currentUser.role !== "development_teacher"
        ) ?
          <Link to={`/institutions/${data.institutionId}`} style={{width: '100%'}}>
            <img
              className={styles["header-logo"]}
              src={data.logo}
              alt="logo"
            ></img>
          </Link> :
          <img
          className={styles["header-logo"]}
          src={data.logo}
          alt="logo"
          ></img>
        }
        <div className={styles["header-info"]} style={{width:'60%'}}>
          {(currentUser.role !== "external_teacher" && 
          currentUser.role !== "internal_teacher" && currentUser.role !== "development_teacher") ?
          <Link to={`/institutions/${data.institutionId}`} style={{color:'#000', fontStyle:'none'}}>
            <div className={styles["header-title"]}>{data.institution}</div></Link> :
            <div className={styles["header-title"]}>{data.institution}</div>
            }
            <div className={styles["header-subtitle"]}>{data.grade}</div>
            <div className={styles["header-subtitle"]}>{data.program}</div>
            <div className={styles["last-block"]} style={{marginTop:'auto'}}>
              <div className={styles.lessons} style={{fontSize:'14px'}}>
                {`${data?.average_completed || 1}/${division || 0}`}
              </div>
              <div className={styles.progressbar} style={{height: '13px', width: '140px'}}>
                <div
                style={{
                  backgroundColor: "var(--sec-green)",
                  borderRadius: "var(--regular-border-radius)",
                  width: `${bar_perc || 0}%`,
                  height: "100%",
                }}
                ></div>
              </div>
          </div>
          </div>
      </div>
    );
  } else if (type === "forum") {
    return (
      <Link
        to={`/courses/${courseId}/forum`}
        style={{ textDecoration: "none", color: "#000" }}
      >
        <div
          className={
            currentOption === "forum"
              ? styles.card_option_selected
              : styles.card_option
          }
          onClick={() => setCurrentOption("forum")}
        >
          <div className={styles["classes-icon"]}>
          <i class="fas fa-comment-dots"></i>
          </div>
          <div className={styles["class-info"]}>
            <div className={styles["header-title"]}>Foro</div>
            <div className={styles.small}>
              <i className="fal fa-chevron-right"></i>
              <span style={{fontSize: '12px'}}>{`Tópicos: ${data?.forum?.topics.length || 0}`}</span>
            </div>
          </div>
            {data.unread_forum && (
              <div className={styles["forum-notifications"]}>
                <i className="far fa-exclamation-circle" style={{fontSize: '1.8rem'}}></i>
              </div>
            )}
        </div>
      </Link>
    );
  } else if (type === "teacher") {
    return (
      <div className={styles.card_option}>
        <div className={styles["user-picture"]}>
          <i className="fas fa-user-circle"></i>
        </div>
          <div className={styles["class-info"]}>
            <div className={styles["header-title"]} style={{fontSize: '14px'}}>{data.display_name}</div>
          </div>
      </div>
    );
  } else if (type === "sections") {
    data?.sort((a, b) => a.name.localeCompare(b.name));
    return (
      <Link
        to={`/courses/${courseId}/sections`}
        style={{ textDecoration: "none", color: "#000" }}
      >
        <div
          className={
            currentOption === "sections"
              ? styles.card_option_selected
              : styles.card_option
          }
          onClick={() => setCurrentOption("sections")}
        >
          <div className={styles["classes-icon"]}>
            <i class="fas fa-chalkboard"></i>
          </div>
          <div className={styles["class-info"]}>
            <div className={styles["header-title"]}>Secciones</div>
            <div className={styles.small}>
              <i className="fal fa-chevron-right"></i>
              {data?.map((sec) => (
                <span key={sec.id} style={{ marginRight: "5px", fontSize: '12px' }}>
                  {sec.name}
                </span>
              ))}
            </div>
          </div>
          <div className={styles["sections"]}>
            <p>{data?.length}</p>
          </div>
        </div>
      </Link>
    );
  } else if (type === "databank") {
    return (
      <Link
        to={`/reference/${data.id}`}
        style={{ textDecoration: "none", color: "#000" }}
      >
        <div className={styles["databank-card"]}>
          <div className={styles["db-first-block"]}>
            <div className={styles["db-info"]}>
              <div className={styles.title}>{data.title}</div>
              
            </div>
          </div>
          <div className={styles["mid-block"]}>
            <div className={styles.notifications}>
              <i className="far fa-exclamation-circle"></i>
              <p>Nuevo</p>
            </div>
          </div>
        </div>
      </Link>
    );
  } else if (type === "databank-add") {
    return (
      // <Link
      //   to={`/reference/${data.id}`}
      //   style={{ textDecoration: "none", color: "#000" }}
      // >
      <div className={styles["databank-card"]} onClick={onClick}>
        <div className={styles["db-first-block"]}>
          <div className={styles["db-info"]}>
            <div className={styles.title}>{data.title}</div>
          </div>
        </div>
        <div className={styles["mid-block"]}>
          <i className={data.icon}></i>
        </div>

        {/* <div className={styles["last-block"]}>
          <div className={styles.programs}>Programas</div>
        </div> */}
      </div>
      // </Link>
    );
  } else if (type === "classes") {
    return (
      <Link
        to={`/courses/${courseId}/classes`}
        style={{ textDecoration: "none", color: "#000" }}
      >
        <div
          className={
            currentOption === "classes"
              ? styles.card_option_selected
              : styles.card_option
          }
          onClick={() => setCurrentOption("classes")}
        >
          <div className={styles["classes-icon"]}>
            <i className="far fa-users-class"></i>
          </div>
          <div className={styles["class-info"]}>
            <div className={styles["header-title"]}>Clases</div>
            <div className={styles.small}>
              <i className="fal fa-chevron-right"></i>
              <span style={{fontSize: '12px'}}>{`Próxima clase: ${nextLesson || "N/A"}`}</span>
            </div>
          </div>
        </div>
      </Link>
    );
  } else if (type === "materials") {
    return (
      <a
        href={url}
        target="_blank" rel="noreferrer" 
        style={{ textDecoration: "none", color: "#000" }}
      >
        <div
          className={
            currentOption === "materials"
              ? styles.card_option_selected
              : styles.card_option
          }
          onClick={() => setCurrentOption("materials")}
        >
          <div className={styles["classes-icon"]}>
            <i className="fas fa-box-open"></i>
          </div>
            <div className={styles["class-info"]}>
              <div className={styles["header-title"]}>Inventario</div>
          </div>
        </div>
      </a>
    );
  } else if (type === "guide") {
    return (
      <a
        href={url}
        target="_blank" rel="noreferrer"
        style={{ textDecoration: "none", color: "#000" }}
      >
        <div
          className={
            currentOption === "guide"
              ? styles.card_option_selected
              : styles.card_option
          }
          onClick={() => setCurrentOption("guide")}
        >
          <div className={styles["classes-icon"]}>
            <i className="fal fa-shapes"></i>
          </div>
          <div className={styles["class-info"]}>
            <div className={styles["header-title"]}>Guía de Armados</div> 
          </div>
        </div>
      </a>
    );
  }else if (type === "bookT") {
    return (
      <a
        href={url}
        target="_blank" rel="noreferrer"
        style={{ textDecoration: "none", color: "#000" }}
      >
        <div
          className={
            currentOption === "book"
              ? styles.card_option_selected
              : styles.card_option
          }
          onClick={() => setCurrentOption("book")}
        >
          <div className={styles["classes-icon"]}>
            <i class="fas fa-book"></i>
            </div>
            <div className={styles["class-info"]}>
              <div className={styles["header-title"]}>Libro - Docente</div>
            </div>
        </div>
      </a>
    );

  }else if (type === "bookS") {
    return (
      <a
        href={url}
        target="_blank" rel="noreferrer"
        style={{ textDecoration: "none", color: "#000" }}
      >
        <div
          className={
            currentOption === "book"
              ? styles.card_option_selected
              : styles.card_option
          }
          onClick={() => setCurrentOption("book")}
        >
          <div className={styles["classes-icon"]}>
            <i class="fas fa-book"></i>
            </div>
            <div className={styles["class-info"]}>
              <div className={styles["header-title"]}>Libro - Alumnos</div>
            </div>
        </div>
      </a>
    );

  }
  else if (type === "indicators") {
    return (
      <a
        href={url}
        target="_blank" rel="noreferrer"
        style={{ textDecoration: "none", color: "#000" }}
      >
        <div
          className={
            currentOption === "indicators"
              ? styles.card_option_selected
              : styles.card_option
          }
          onClick={() => setCurrentOption("indicators")}
        >
          <div className={styles["classes-icon"]}>
            <i class="fas fa-list-ul"></i>
            </div>
            <div className={styles["class-info"]}>
              <div className={styles["header-title"]}>Indicadores</div>
            </div>
        </div>
      </a>
    );
  }
};

export default Card;
