/**
 * Genera una presentación en PowerPoint utilizando `pptxgenjs` para una institución.
 *
 * Esta función construye una presentación de diapositivas basada en datos e imágenes proporcionadas,
 * incluyendo información sobre la institución, habilidades, asuntos pendientes, y una serie
 * de secciones predefinidas como Beneficios Kurios y Alianzas.
 *
 * @param {Array} images - Arreglo de objetos que contiene las imágenes (en formato Base64) y sus nombres.
 * @param {Object} institution - Objeto que representa la institución para la que se genera el PPT.
 * @param {Array} asuntosEmergentes - Arreglo de puntos pendientes a tratar, que serán agregados en la presentación.
 * @param {Array} optionalSlides - Arreglo que contiene información sobre las diapositivas opcionales a incluir.
 * @param {Array} teacherData - Datos de los profesores, incluyendo sus habilidades y seguimiento en capacitaciones.
 * @param {string} linkDashboard - Enlace a la información de la alianza, que se agregará en la diapositiva de Alianzas.
 * @param {function} setLoading - Función para activar o desactivar el estado de carga durante la generación del PPT.
 */
import React from 'react'
import PptxGenJS from 'pptxgenjs';
import { skills , yearStageImages} from './constantInstitution';
/**
 * Función auxiliar que obtiene el valor de una diapositiva opcional por nombre
 * Se debe utilizar para las laminas opcionales (proyeccion academica)
 * */
const getValueByName = (optionalSlides, name) => {
  const option = optionalSlides?.find(option => option.name === name);
  return option ? option.valor : false;
}

export const GeneratePPT = ( images, institution, asuntosEmergentes, optionalSlides, teacherData, linkDashboard, setLoading ) => {
    let pptx = new PptxGenJS();
    // DIAPOSITIVA PORTADA
    const slidePortada = pptx.addSlide();
    slidePortada.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/portada.png', x: 0, y: 0, w: '100%', h: '100%' });
    //Agregar logo institucion    
    const url = institution?.logo;
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    const relativePath = pathname.substring(1);
    //NUEVA URL PARA LOGO
    const newUrl = `https://kuriosedu.s3.us-east-2.amazonaws.com/${relativePath}`
    slidePortada.addImage({ path: newUrl, x: 5.2, y: 0.5,  w: 1.4,  h: 1.4 });
    // aqui estaba y 0.5 e w y h en 1 
    // DIAPOSITIVA DE TEMAS A TRATAR
    const slideTemas = pptx.addSlide();
    slideTemas.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/ptosTratar.png', x: 0, y: 0, w: '100%', h: '100%' });

    //DIAPOSITIVA PORTADA AVANCES DEL LAPSO Y PROYECCION
    const slideAvances = pptx.addSlide();
    slideAvances.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/fondoVerde.png', x: 0, y: 0, w: '100%', h: '100%' });
    slideAvances.addText("Avances del lapso y proyección académica", {
        x: 0.5,
        y: 2.5,
        fontSize: 22,
        color: 'FFFFFF',
        bold: true,
        fontFace:'Soho Gothic'
    }); 

    //DIAPOSITIVA DE PROYECCION ACADEMICA
    const isYearSelected = (optionalSlides, year) => getValueByName(optionalSlides, year);
    // Función para agregar las diapositivas correspondientes a cada año y etapa
    const addSlidesForYearAndStage = (year, stage) => {
      const images = yearStageImages[year]?.[stage] || [];
      images.forEach((imagePath) => {
        const slide = pptx.addSlide();
        slide.addImage({ path: imagePath, x: 0, y: 0, w: '100%', h: '100%' });
      });
    };
    const uniqueStages1 = new Set();
    const stageOrder1 = ['Preescolar', 'Primaria', 'Bachillerato'];// Orden de los stages

    teacherData?.forEach((teacher) => {
      const normalizedStages1 = teacher?.stages?.map(stage => {
        if (stage === "Primaria Menor" || stage === "Primaria Mayor") {
          return "Primaria";
        }
        return stage;
      });
      normalizedStages1.forEach(stage => uniqueStages1.add(stage));
    });
    // Proyeccion academica por etapa y año
    ['Año 1', 'Año 2', 'Año 3','Año 4+', 'Digital Skills - Marketplace',
       'Digital Skills I y II - Marketplace', 
       'Digital Skills II - Marketplace'].forEach(year => {
      if (isYearSelected(optionalSlides, year)) {
        stageOrder1.forEach(stage => {
          if (uniqueStages1.has(stage)) {
            addSlidesForYearAndStage(year, stage);
          }
        });
      }
    });
    //DIAPOSITIVA PORTADA EXPERIENCIA KURIOS
    const slideExperiencia = pptx.addSlide();
    slideExperiencia.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/fondoAzul.png', x: 0, y: 0, w: '100%', h: '100%' });
    slideExperiencia.addText("Experiencia Kurios", {
        x: 0.5,
        y: 2.5,
        fontSize: 22,
        color: 'FFFFFF',
        bold: true,
        fontFace:'Soho Gothic'
    }); 

    //COLLAGE EXPERIENCIA KURIOS
    let slideCollage = pptx.addSlide();
    //Formato Cuadradro de 4 imagenes
    // const positions = [
    //   { x: 0, y: 0, w: 5, h: 2.8 },  // Imagen 1 (Arriba izquierda)
    //   { x: 5, y: 0, w: 5, h: 2.8 },  // Imagen 2 (Arriba derecha)
    //   { x: 0, y: 2.8, w: 5, h: 2.8 },  // Imagen 3 (Abajo izquierda)
    //   { x: 5, y: 2.8, w: 5, h: 2.8 },  // Imagen 4 (Abajo derecha)
    // ]; 
    //Formato Vertical centro (dos imagenes) Vertical 
    const positions = [ 
      { x: 0, y: 0, w: 3.33, h: 5.63 }, // Imagen 1 (Izquierda toda la altura) 
      { x: 3.33, y: 0, w: 3.33, h: 2.83 }, // Imagen 2 (Centro superior) 
      { x: 3.33, y: 2.8, w: 3.33, h: 2.83 }, // Imagen 3 (Centro inferior) 
      { x: 6.66, y: 0, w: 3.35, h: 5.63 }, // Imagen 4 (Derecha toda la altura) 
      ];
    images.forEach((image, index) => {
      if (image.image) {
        slideCollage.addImage({
          data: image.image,  // Base64 image
          x: positions[index].x,
          y: positions[index].y,
          w: positions[index].w,
          h: positions[index].h,
        });
      }
    });
    slideCollage.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
      x: 5,         // Posición horizontal (en la parte inferior derecha)
      y: 4.8,       // Posición vertical (parte inferior de la diapositiva)
      w: 5,         // Ancho de la franja
      h: 0.5,         // Alto de la franja
      fill: { color: '330072' }, // Color morado
      rectRadius: 5,  // Radio de 20px en la esquina superior izquierda
    });
    slideCollage.addText('Experiencia Kurios I Lapso', {
      x: 5.1,  // Alineado con la franja (levemente ajustado para centrado)
      y: 4.6,  // Alineado verticalmente con la franja
      w: 4,  // Ancho del área de texto
      h: 0.8,  // Altura del área de texto
      fontSize: 20,
      color: 'FFFFFF',  // Texto blanco
      bold: true,
      align: 'center',  // Texto centrado
    });

    //DIAPOSITIVA PORTADA SEGUIMIENTO DOCENTES
    const slideSeguimiento = pptx.addSlide();
    slideSeguimiento.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/fondoMorado.png', x: 0, y: 0, w: '100%', h: '100%' });
    slideSeguimiento.addText("Seguimiento a los docentes", {
        x: 0.5,
        y: 2.5,
        fontSize: 22,
        color: 'FFFFFF',
        bold: true,
        fontFace:'Soho Gothic'
    }); 

    // DIAPOSITIVA ANÁLISIS DE ACOMPAÑAMIENTO
  let slideAnalisis = pptx.addSlide();
  const addSkillsToSlide = (slide) => {
  slide.addText('Análisis del acompañamiento a los docentes', {
    x: 0.4,
    y: 0.45,
    fontSize: 20,
    bold: true,
    color: '330072', // Color morado
    fontFace:'Open Sans'
  });

  slide.addText('Habilidades / Programas', {
    x: 0.5,
    y: 0.9,
    w: 4.2,
    fontSize: 12,
    bold: true,
    color: '330072',
    align: 'center',
    fontFace:'Open Sans'
  });
  

  // Añadir habilidades
  skills?.forEach((skill, index) => {
    let yPosition = 1.3 + index * 0.28; // Espaciado entre habilidades
    let color;
    if (index < 4) {
      color = 'EFEFEF'; // Primeros 4 items
    } else if (index >= 4 && index < 12) {
      color = 'CFE2F4'; // Próximos 8 items
    } else {
      color = 'B5A8D6'; // Últimos 2 items
    }
    // Fondo para cada habilidad
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
      x: 0.4,
      y: yPosition,
      w: 4.2, // Anchura de la columna de habilidades
      h: 0.23, // Altura de cada fila de habilidad
      fill: { color: color },
      radius: 5, // Bordes redondeados
    });

    // Texto de la habilidad
    slide.addText(skill, {
      x: 0.45, // Un poco dentro del rectángulo
      y: yPosition + 0.1, // Centrado verticalmente
      fontSize: 8,
      w: 4.2,
      align:'center',
      color: '330072', // Texto en negro
      fontFace:'Open Sans'
    });
  });
  };

  // Llamar la función para agregar las habilidades a la primera diapositiva
  addSkillsToSlide(slideAnalisis);

  let columnCount = 0; // Contador de columnas por diapositiva
  const uniqueStages = new Set();
  const stageOrder = ['Preescolar', 'Primaria', 'Bachillerato'];// Orden de los stages
  let teacherActive = new Set();
  teacherActive = teacherData?.filter((teacher) => teacher?.info?.is_active === true && teacher?.stages?.length > 0);
  teacherActive?.forEach((teacher) => {
  // Reemplazar "Primaria Menor" y "Primaria Mayor" por "Primaria"
  const specificColorForRow14 = (teacher?.encuestasListas === teacher?.encuestas?.length) ? 'A2D394' : (teacher?.encuestasListas > 0 ? 'FFD394' : 'EFEFEF'); 
  const normalizedStages = teacher?.stages?.map(stage => {
    if (stage === "Primaria Menor" || stage === "Primaria Mayor") {
      return "Primaria";
    }
    return stage;
  });
  // Eliminar duplicados en los stages normalizados y ordenar los stages
  const uniqueTeacherStages = [...new Set(normalizedStages)].sort((a, b) => {
    return stageOrder.indexOf(a) - stageOrder.indexOf(b);
  });
    uniqueTeacherStages?.forEach((stage) => {
    if (!uniqueStages.has(stage)) {

      uniqueStages.add(stage); // Agregar el stage al Set si no ha sido utilizado aún
      let columnIndex = uniqueStages.size - 1;
      columnCount++; // Incrementar el contador de columnas por diapositiva
      // Si ya hemos agregado 3 columnas, creamos una nueva diapositiva y volvemos a añadir las habilidades
      let xPosition = 4.8 + columnIndex * 1.7;
      // Calcular la posición de la columna basada en la cantidad de stages únicos ya agregados
      // Mover las columnas dependiendo de la cantidad de stages
      // Añadir un rectángulo detrás del nombre del docente (stage)
      slideAnalisis.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: xPosition,
        y: 0.7,  // Un poco antes del texto del nombre para que se vea bien el fondo
        w: 1.5,   // Ancho del rectángulo (igual que el texto)
        h: 0.5,   // Altura del rectángulo
        fill: { color: 'B5A8D6' }, 
        radius: 5,  // Bordes redondeados
      });
      // Añadir nombre del docente y stage 
      slideAnalisis.addText(stage, {
        x: xPosition,
        y: 0.84,  // Posición vertical para el stage
        fontSize: 10,
        bold: true,
        color: 'FFFFFF',  
        w: 1.5,  
        align: 'center',
        fontFace: 'Open Sans'
      });
      
      // Agrega el nombre debajo del "stage"
      slideAnalisis.addText(`${teacher?.info?.first_name} ${teacher?.info?.last_name}`, {
        x: xPosition,
        y: 1.04,  // Posición vertical para el nombre (ajustada para que quede debajo)
        fontSize: 10,
        bold: true,
        color: 'FFFFFF',  
        w: 1.5,  
        align: 'center',
        fontFace: 'Open Sans'
      });

      // Añadir los rectángulos para las habilidades
      skills?.forEach((_, skillIndex) => {
        //Dentro de esta funcion es donde se debe de agregar un addText para el % de interaccion para el indice 14
        let yPosition = 1.3 + skillIndex * 0.28; // Coincidir con la posición de las habilidades

        //Ajusta el color para la habilidad con indice 13
        let fillColor = (skillIndex === 12) ? specificColorForRow14 : 'EFEFEF';

        // Añadir un rectángulo para el estado de la habilidad
        slideAnalisis.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
          x: xPosition,
          y: yPosition,
          w: 1.5,  // Ancho de cada columna para el stage
          h: 0.23, // Altura de cada fila de habilidad
          fill: { color: fillColor },
          radius: 5, // Bordes redondeados
        });
      });
      if (columnCount === 3) {
        slideAnalisis = pptx.addSlide(); // Crear nueva diapositiva
        addSkillsToSlide(slideAnalisis); // Añadir las habilidades a la nueva diapositiva
        columnCount = 0; // Reiniciar el contador de columnas para la nueva diapositiva
        uniqueStages.clear();
      }
    }
  });
  });

    //DIAPOSITIVA PORTADA DE PUNTOS PENDIENTES
    const slidePtosPendientes = pptx.addSlide();
    
    slidePtosPendientes.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/fondoAzul.png', x: 0, y: 0, w: '100%', h: '100%' });
    slidePtosPendientes.addText("Puntos pendientes", {
        x: 0.4,
        y: 2.5,
        fontSize: 22,
        color: 'FFFFFF',
        bold: true,
        fontFace:'Soho Gothic'
    }); 
    //DIAPOSITIVAS PUNTOS PENDIENTES
    if(asuntosEmergentes?.length > 0 ){
      const slidePtosPendientes2 = pptx.addSlide();
      slidePtosPendientes2.addText('Puntos pendientes', {
        x: 0.5,
        y: 0.8,
        fontSize: 20,
        bold: true,
        color: '330072',
        fontFace:'Open Sans'
      });
      const leftPoints = asuntosEmergentes.slice(0, 3);
      const rightPoints = asuntosEmergentes.slice(3, 6);
      const leftColumnX = 0.4;
      const rightColumnX = 5;
      let yPosition = 1.5;

      const addPointsToColumn = (points, columnX, startIndex) => {
        points.forEach((point, index) => {
          // Añadir la imagen del número
          slidePtosPendientes2.addImage({
            path: `https://kuriosedu.s3.us-east-2.amazonaws.com/media/doodles${startIndex + index}.png`, 
            x: columnX,
            y: yPosition,
            w: 0.5,
            h: 0.5,
          });
      
          // Añadir el texto del punto pendiente
          slidePtosPendientes2.addText(point, {
            x: columnX + 0.5, // Un poco más a la derecha de la imagen
            y: yPosition + 0.28, // Alineado con la imagen del número
            fontSize: 11,
            color: '000000', // Texto en negro
            w: 4, // Ancho del área de texto
            fontFace: 'Open Sans'
          });
      
          yPosition += 1.3; // Espaciado entre los puntos
        });
      };

      addPointsToColumn(leftPoints, leftColumnX, 0);

      // Reiniciar la posición y añadir puntos a la columna derecha
      yPosition = 1.5;
      addPointsToColumn(rightPoints, rightColumnX, 3);
      
    }
    //PORTADA SUMMIT
    const slideSummit = pptx.addSlide();
    
    slideSummit.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/fondoVerde.png', x: 0, y: 0, w: '100%', h: '100%' });
    slideSummit.addText("Kurios Summit Noviembre 2024", {
        x: 0.5,
        y: 2.5,
        fontSize: 22,
        color: 'FFFFFF',
        bold: true,
        fontFace:'Soho Gothic'
    });

    const slideSummit1 = pptx.addSlide();
    slideSummit1.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/summit2.png', x: 0, y: 0, w: '100%', h: '100%' });

    //PORTADA KURIOS COMPETITION
    const slideCompetition = pptx.addSlide();
    
    slideCompetition.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/fondoNaranja.png', x: 0, y: 0, w: '100%', h: '100%' });
    slideCompetition.addText("Kurios Competition", {
        x: 0.5,
        y: 2.5,
        fontSize: 22,
        color: 'FFFFFF',
        bold: true,
        fontFace:'Soho Gothic'
    }); 

    const slideTorneo1 = pptx.addSlide();
    slideTorneo1.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/torneo1.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideTorneo2 = pptx.addSlide();
    slideTorneo2.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/torneo2.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideTorneo3 = pptx.addSlide();
    slideTorneo3.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/torneo3.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideTorneo4 = pptx.addSlide();
    slideTorneo4.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/torneo4.png', x: 0, y: 0, w: '100%', h: '100%' });

    //PORTADA BENEFICIOS
    const slideBeneficios = pptx.addSlide();
    slideBeneficios.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/fondoMorado.png', x: 0, y: 0, w: '100%', h: '100%' });
    slideBeneficios.addText("Beneficios Kurios", {
        x: 0.5,
        y: 2.5,
        fontSize: 22,
        color: 'FFFFFF',
        bold: true,
        fontFace:'Soho Gothic'
    }); 

    const slideBeneficios1 = pptx.addSlide();
    slideBeneficios1.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/beneficios.png', x: 0, y: 0, w: '100%', h: '100%' });

    // ISEF  Y THINKOAI
    const slideBeneficios2 = pptx.addSlide();
    slideBeneficios2.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/beneficios11.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideBeneficios3 = pptx.addSlide();
    slideBeneficios3.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/beneficios12.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideBeneficios4 = pptx.addSlide();
    slideBeneficios4.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/beneficios13.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideBeneficios5 = pptx.addSlide();
    slideBeneficios5.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/beneficios14.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideBeneficios6 = pptx.addSlide();
    slideBeneficios6.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/beneficios21.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideBeneficios7 = pptx.addSlide();
    slideBeneficios7.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/ThinkoAI2.png', x: 0, y: 0, w: '100%', h: '100%' });

    
    //DIAPOSITIVA GRACIAS
    const slideFinal = pptx.addSlide();
    slideFinal.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/final.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideBeneficios8 = pptx.addSlide();
    slideBeneficios8.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/beneficios2.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideBeneficios9 = pptx.addSlide();
    slideBeneficios9.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/beneficios3.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideBeneficios10 = pptx.addSlide();
    slideBeneficios10.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/beneficios4.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideBeneficios11 = pptx.addSlide();
    slideBeneficios11.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/beneficios5.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideBeneficios12 = pptx.addSlide();
    slideBeneficios12.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/beneficios6.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideBeneficios13 = pptx.addSlide();
    slideBeneficios13.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/beneficios7.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideBeneficios14 = pptx.addSlide();
    slideBeneficios14.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/beneficios8.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideBeneficios15 = pptx.addSlide();
    slideBeneficios15.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/beneficios9.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideBeneficios16 = pptx.addSlide();
    slideBeneficios16.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/beneficios10.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideBeneficios17 = pptx.addSlide();
    slideBeneficios17.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/beneficios15.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideBeneficios18 = pptx.addSlide();
    slideBeneficios18.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/beneficios16.png', x: 0, y: 0, w: '100%', h: '100%' });
    
    const slideBeneficios19 = pptx.addSlide();
    slideBeneficios19.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/beneficios17.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideBeneficios20 = pptx.addSlide();
    slideBeneficios20.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/beneficios19.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideBeneficios21 = pptx.addSlide();
    slideBeneficios21.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/beneficios20.png', x: 0, y: 0, w: '100%', h: '100%' });
    
    // Descargar la presentación
    pptx.writeFile({ fileName: `Presentación-${institution?.name}` });
    setLoading(false)
}
