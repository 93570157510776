import React, {useState} from 'react'
import Skeleton from '@mui/material/Skeleton';
import Modal from '../Modal'

const cuadroInfo = [
    {stage: 'Preescolar',
    img: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/Cuadro+de+Honor+Preescolar.png'
    },
    {stage: 'Primaria',
    img: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/Cuadro+de+Honor+Primaria.png'
    },
    {stage: 'Bachillerato',
    img: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/Historias+-+Cuadro+de+Honor+EMG.png'
    }
]

function CuadroHonor({setModalHonor, currentStage}) {
    const [showAnimation, setShowAnimation] = useState(true);
    const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };
    const filteredResults = cuadroInfo?.filter((item) => item?.stage === currentStage)
  return (
    <Modal title={"Cuadro de Honor Kurios - 1er Lapso"} 
    modalOpen={setModalHonor}
    showAnimation={showAnimation}>
        <div style={{width: '100%', maxHeight: '80vh', overflowY: 'scroll', display: 'flex', 
        flexDirection: 'column', gap: '15px', justifyContent:'center'}}>
          {loading && (
            <Skeleton
              variant="rectangular"
              width={400}
              height={350}
              animation="wave"
            />
        )}
            <img src={filteredResults[0]?.img} width={400}
            onLoad={handleImageLoad} style={{ display: loading ? 'none' : 'block' }} />
            

        </div>
    </Modal>
  )
}

export default CuadroHonor
