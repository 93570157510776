import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Grid, Button, Link, Box} from "@mui/material";
import { MySubmitButton } from "../../FormFields";
import { CapacitacionesContext } from "../../../context/CapacitacionesContext";
// import YoutubeEmbed from "../../../utils/YoutubePreview";
// import extractVideoId from "../../../utils/extractVideoId";
import styles from "./CapacitacionTeacher.module.css";
import Container from "../../Container";
import { CAPACITACION_ENDPOINT } from "../../../utils/constants";
import { wordCapitalize } from "../../../utils/capitalize";
import { get } from "../../../utils/http";
import { CurrentUserContext } from "../../../context/CurrentUserProvider";
import { dateFormatter } from "../../../utils/dateFormatter";
import { handleDownload } from "../../../utils/downloadFile";
import QuizView from "./QuizView";
import { useLoading } from "../../../context/LoadingContext";

function CapacitacionTeacher() {
  const { sendAssignment } = useContext(CapacitacionesContext);
  const [capacitacion, setCapacitacion] = useState([]);
  const [showFileUploader, setShowFileUploader] = useState(true);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFileName] = useState("");
  const { showLoading, hideLoading, isLoading } = useLoading();
  const [file, setFile] = useState({});
  const { currentUser } = useContext(CurrentUserContext);
  const fileInputRef = useRef(null);

  const { capacitacionId } = useParams();

  const navigate = useNavigate();
  
  const getCapacitacion = async () => {
    const url = `${CAPACITACION_ENDPOINT}${capacitacionId}/my_training/`;
    const response = await get({ url });
    setCapacitacion(response.data);
  };

  useEffect(() => {
    getCapacitacion();
  }, [capacitacionId]);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleOnChange = (e) => {
    setShowFileUploader(false);
    setFileName(e.target.files[0].name);
    setFile(e.target.files[0]);
    setFileUploaded(true);
  };

  const contenidoSinComillas = capacitacion?.task_info?.replace(/^"|"$/g, "");

  const createHandler = async (data) => {
    const response = await sendAssignment({ data });
    getCapacitacion();
    setShowFileUploader(true);
    setFileUploaded(false);
  };

  const tasksFilter = capacitacion?.tasks?.filter((item) => item?.teacher === currentUser?.id)
  return (
    <Container>
      <div className={styles.container}>
        <i
          className="fal fa-arrow-left"
          style={{ fontSize: "12px", cursor: "pointer", marginBottom: "10px" }}
          onClick={() => navigate(-1)}
        ></i>
        <div>
          <p className={styles.title}>{wordCapitalize(capacitacion?.title)}</p>
        </div>
        <Grid container direction={"row"} columnGap={1} rowGap={1}>
          <Grid item  md = {12} xs={12} sm={12} className={styles.stylegrid}>
            <div className={styles.description}>
                <p>
                  En el marco de las formaciones continuas, se requiere completar la actividad asignada y entregar un archivo con la documentación correspondiente. A continuación, se detallan los pasos a seguir:
                </p><br/>
                <ul>
                  <li><p style={{ marginLeft: "10px" }}><b>A) Realización de la Actividad:</b> Complete todas las tareas y ejercicios especificados en la actividad de formación. Asegúrese de seguir todas las instrucciones proporcionadas para cada paso de la actividad.</p><br/></li>
                  <li><p style={{ marginLeft: "10px" }}><b>B) Entrega del Archivo:</b> Suba el archivo preparado a la plataforma en la sección de entregas (Click para subir archivo). Recuerde seguir las instrucciones proporcionadas en la formacion para la carga del archivo.</p><br/></li>
                  <li><p style={{ marginLeft: "10px" }}><b>C) Completar la autoevaluación:</b> Después de entregar el archivo, acceda al formulario proporcionado en la plataforma en la sección autoevaluación. Complete el formulario con la información requerida.</p><br/></li>
                </ul>
                <p style={{marginLeft: '30px'}}>Para responder el formulario, tenga en cuanta la siguiente escala del 1 al 5:</p>
                <br/>
                <div style={{marginLeft: '30px'}}>
                  <p style={{ marginLeft: "10px" }}><b>1:</b> No lo logré en absoluto. No pude completar las tareas relacionadas con este indicador o no cumplí con los criterios establecidos.</p><br/>
                  <p style={{ marginLeft: "10px" }}><b>2:</b> Lo logré en parte. Tuve dificultades para cumplir con algunos de los criterios y requiero más práctica o apoyo.</p><br/>
                  <p style={{ marginLeft: "10px" }}><b>3:</b> Lo logré de manera satisfactoria. Cumplí con la mayoría de los criterios y demostré un nivel básico de competencia.</p><br/>
                  <p style={{ marginLeft: "10px" }}><b>4:</b> Lo logré muy bien. Superé las expectativas y demostré un alto nivel de competencia en este indicador.</p><br/>
                  <p style={{ marginLeft: "10px" }}><b>5:</b> Lo dominé por completo. Logré todos los objetivos y demostré un dominio excepcional de este indicador.</p><br/>

                </div>
            </div>
                
          </Grid>
          <Grid item  md = {8.9} xs={8.85} sm={8.85} className={styles.stylegrid}>
                <p className={styles.description}>{capacitacion?.description}</p>
          </Grid>
          <Grid item  md = {3} xs={3} sm={3} className={styles.stylegrid}>
            <Grid container direction={"column"} columnGap={1} rowGap={1}>                 
              {capacitacion?.resources?.map((resource, index) => ( 
                <Grid item xs={2.93} md={2.98} key={index}>
                  {resource.type === 'GUIDE' && ( 
                    <Link href={resource.url} 
                      target="_blank" rel="noopener noreferrer"> 
                      <Button variant="outlined" sx={{ borderRadius: '10px', width: 
                       '100%', height: '100%', backgroundColor: '#fff', fontSize: '11px' }}
                        startIcon={<i className="fa fa-book"></i>} > 
                          {resource.name} 
                       </Button> 
                     </Link> 
                  )} 
                  {resource.type === 'VIDEO' && (
                    <Link href={resource.url} target="_blank" rel="noopener noreferrer">
                      <Button
                        variant="outlined"
                        sx={{borderRadius: '10px', 
                        width:'100%', 
                        height:'100%', backgroundColor: '#fff', fontSize:'11px'}}
                        startIcon={<i className="fab fa-youtube"></i>}
                        >
                          {resource.name}
                      </Button>
                    </Link>
                  )}
                  {resource.type === 'PROGRAM' && ( 
                    <Link href={resource.url} 
                      target="_blank" rel="noopener noreferrer"> 
                      <Button variant="outlined" sx={{ borderRadius: '10px', width: 
                        '100%', height: '100%', backgroundColor: '#fff', fontSize: '11px' }}
                        startIcon={<i className="fas fa-books"></i>} > 
                        {resource.name} 
                      </Button> 
                    </Link> 
                  )} 

                  {resource.type === 'SCREENSHOT' && ( 
                    <Link href={resource.url} 
                      target="_blank" rel="noopener noreferrer"> 
                      <Button variant="outlined" sx={{ borderRadius: '10px', width: 
                        '100%', height: '100%', backgroundColor: '#fff', fontSize: '11px' }}
                        startIcon={<i className="far fa-image"></i>} > 
                          {resource.name} 
                      </Button> 
                    </Link> 
                  )} 
                  {resource.type === 'RESOURCE' && (
                    <Link href={resource.url} target="_blank" rel="noopener noreferrer">
                      <Button
                        variant="outlined"
                        sx={{borderRadius: '10px', 
                        width:'100%', 
                        height:'100%', backgroundColor: '#fff', fontSize:'11px'}}
                        startIcon={<i class="fad fa-chalkboard"></i>}
                      >
                        {resource.name}
                      </Button>
                    </Link>
                  )}
                  </Grid> 
                ))}
                  </Grid>
          </Grid>
              
          </Grid>
        <div>
          {capacitacion?.task_info !== ' ' && 
          <>
          <p className={ styles.title2}
            >Asignación</p>
          <div className={styles.task_info}>
            <div dangerouslySetInnerHTML={{ __html: contenidoSinComillas }} style={{fontSize: '14px'}} />
          </div> 
          </>}
          {capacitacion?.guide && (
            <>
              <p className={styles.title2}>Actividad</p>
              <div className={styles.task_info}>
                <a target="_blank" rel="noreferrer" href={capacitacion.guide}
                 style={{textDecoration: 'none', fontSize: '16px', color: 'var(--main-blue)'}} >
                   <i class="fad fa-book-reader" style={{fontSize: '18px', marginRight: '5px', color: 'var(--main-blue)'}}></i> Ver guía </a>
              </div>
            </>
          )}
          
          {capacitacion?.task_info && 
          <>
          {tasksFilter?.length === 0 ? (
            <Formik
              initialValues={{
                stage: "",
              }}
              validationSchema={Yup.object({
                stage: Yup.string().min(1, "El campo no puede estar vacío."),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const formData = new FormData();
                const date = new Date();
                const formattedDate = date.toISOString();
                formData.append("training", capacitacionId);
                formData.append("date", formattedDate);
                formData.append("teacher", currentUser.id);
                formData.append("file", file);
                setTimeout(() => {
                  createHandler(formData);
                  setSubmitting(false);
                }, 400);
                resetForm();
              }}
            >
              <Form className={styles["form"]}>
                <div className={styles["form__fields"]}>
                  <div className={styles.wrapper}>
                    {showFileUploader && (
                      <form
                        action="#"
                        onClick={handleUploadClick}
                        encType="multipart/form-data"
                      >
                        <input
                          type="file"
                          style={{ display: "none" }}
                          ref={fileInputRef}
                          onChange={handleOnChange}
                        ></input>
                        <i className="fas fa-cloud-upload-alt"></i>
                        <p>{`Click para subir archivo`}</p>
                      </form>
                    )}
                    {fileUploaded && (
                      <section className={styles.uploadedarea}>
                        <li className={styles.row}>
                          <div className={styles.content}>
                            <i
                              className="fas fa-file-alt"
                              style={{ fontSize: "20px" }}
                            ></i>
                            <div className={styles.details}>
                              <span
                                className={styles.name}
                              >{`${fileName} • Uploaded`}</span>
                            </div>
                          </div>
                          <i
                            className="fas fa-check"
                            style={{ fontSize: "20px" }}
                          ></i>
                        </li>
                        <MySubmitButton name="Cargar" type="submit" />
                      </section>
                    )}
                  </div>
                </div>
              </Form>
            </Formik>
          ) : (
            <div>
              {tasksFilter?.map((task) => {
                return (
                  <div className={styles.task}>
                    <div className={styles.task_row}>
                      <div
                        style={{
                          width: "20%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ fontSize: "11px", textAlign: "center" }}>
                          Fecha de Entrega
                        </p>
                        <p
                          style={{
                            fontSize: "13px",
                            textAlign: "center",
                            paddingTop: "5px",
                          }}
                        >
                          {dateFormatter(task.date)}
                        </p>
                      </div>
                      <div
                        style={{
                          width: "20%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ fontSize: "11px", textAlign: "center" }}>
                          Nota
                        </p>
                        <p
                          style={{
                            fontSize: "13px",
                            textAlign: "center",
                            paddingTop: "5px",
                          }}
                        >
                          {task.reviewed ? task.grade : "Sin corregir"}
                        </p>
                      </div>
                      <div
                        style={{
                          width: "20%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <p className={styles.submitted}>Entregado</p>
                      </div>
                      <div
                        style={{
                          width: "20%",
                          display: "flex",
                          justifyContent: "center",
                          marginLeft: "auto",
                        }}
                      >
                        <p
                          className={styles.comment}
                          onClick={() => handleDownload(task.file)}
                        >
                          Ver mi Entrega
                        </p>
                      </div>
                    </div>
                    {task.reviewed && (
                      <div
                        style={{
                          width: "100%",
                          justifyContent: "flex-start",
                          display: "flex",
                          flexDirection: "column",
                          borderTop: "1px solid #989a9e",
                        }}
                      >
                        <p
                          style={{
                            textAlign: "start",
                            fontSize: "15px",
                            fontWeight: "bold",
                            paddingTop: "10px",
                          }}
                        >
                          Comentario
                        </p>
                        <p style={{ textAlign: "start", fontSize: "14px" }}>
                          {task.comment}
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}

          {capacitacion?.quiz && 
            <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
              <p className={styles.title2}>Autoevaluación</p>
              <QuizView getCapacitacion={getCapacitacion} quizName={capacitacion?.quiz?.description}
              quiz={capacitacion?.quiz?.templatequiz} quizId={capacitacion?.quiz?.id} quizUser ={capacitacion?.quiz_user}/>
            </div>
          }
          </>}
        </div>
      </div>
    </Container>
  );
}

export default CapacitacionTeacher;
