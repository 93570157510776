import React, { useState, useEffect, useContext } from "react";
import {
  useNavigate,
  useLocation,
  Outlet,
  useParams,
  Link,
} from "react-router-dom";
import { Grid, Button, IconButton, Box } from "@mui/material";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import InfoIcon from '@mui/icons-material/Info';
import { useCourses } from "../context/CoursesContext";
import Card from "../components/Card/Card";
import styles from "./CoursePage.module.css";
import { useUsers } from "../context/UsersProvider";
import { CurrentUserContext } from "../context/CurrentUserProvider";
import { versionRules } from "../utils/constants/versionsRules";

const CoursePage = () => {
  const { fetchSingleCourse } = useCourses();
  const { currentUser } = useContext(CurrentUserContext);
  const [currentOption, setCurrentOption] = useState("classes");
  const [course, setCourse] = useState({});
  const [courseInfo, setCourseInfo] = useState(null);
  const [forum, setForum] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentVersion, setCurrentVersion] = useState('1')
  const [isLeftSideOpen, setIsLeftSideOpen] = useState(true);
  const [versions, setVersions] = useState([])
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(950))

  const navigate = useNavigate();
  const location = useLocation();

  const { courseId } = useParams();

  const { getTeachers } = useUsers();

  useEffect(() => {
    if (!location.pathname.split("/")[3]) {
      navigate("classes", { replace: true });
    } else {
      setCurrentOption(location.pathname.split("/")[3]);
    }
  }, [location.pathname]);

  const getCourse = async () => {
    const course = await fetchSingleCourse(courseId);
    const courseInfo = {
      institutionId: course?.institution?.id,
      logo: course?.institution?.logo,
      institution: course?.institution?.name,
      program: course?.program,
      programversionslength: course?.program_versions.length,
      grade: course?.institution_level,
      currentLesson: course?.next_lesson?.lesson_number,
      lessonCount: course?.lesson_count,
      average_completed: course?.average_completed,
    };
    const forumInfo = {
      forum: course?.forum,
      unread_forum: course?.unread_forum,
    };
    setForum(forumInfo);
    setCourse(course);
    setCurrentVersion(course?.program_versions[0])
    setCourseInfo(courseInfo);
    setTeachers(getTeachers(course?.course_teachers));
  };

  useEffect(() => {
    getCourse();
  }, [courseId, fetchSingleCourse]);

  useEffect(() => {
    if (course?.program) {
      const programVersion = versionRules?.find((item) => item.program === course.program)
      setVersions(programVersion?.versions)
    } 
  }, [course, versionRules]);

  useEffect(() => {
    if (courseInfo) {
      setLoading(false);
    }
  }, [courseInfo]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Timeout de 3 segundos para manejar casos donde ambos arrays estén vacíos

    return () => clearTimeout(timer);
  }, []);

  const handleChangeActiveVersion = (version) => {
    setCurrentVersion(version);
  };

  return (
    <>
    <i class="fas fa-arrow-left" onClick={() => navigate(-1)} 
        style={{cursor: 'pointer', fontSize: '14px'}}></i>
    <div className={styles.wrapper}>
      <Box sx={{width:'100%', display:'flex', 
        justifyContent:'flex-end'}}>
        <Tooltip
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              fontSize: '1.2rem',
            },
          },
        }}
        title={isLeftSideOpen ? 'Esconder Encabezado' : "Mostrar Encabezado"}>
        <IconButton
          onClick={() => setIsLeftSideOpen(!isLeftSideOpen)}
          sx={{width:'fit-content', marginRight: '10px'}}
        >
          {isLeftSideOpen ? <CloseFullscreenIcon fontSize="large" 
          sx={{color: 'var(--main-blue)'}} /> :
           <OpenInFullIcon fontSize="large"
           sx={{color: 'var(--main-blue)'}} />}
        </IconButton>
        </Tooltip>

      </Box>
      <div className={styles.leftside}
      style={{ display: isLeftSideOpen ? 'flex' : 'none' }}>
        {loading ? 
        <>
        <Skeleton
        variant="rectangular"
        width="30%"
        height={'166px'}
        animation="wave"
        style={{ borderRadius: '10px' }}
        />
        <Skeleton
        variant="rectangular"
        width="30%"
        height={'166px'}
        animation="wave"
        style={{ borderRadius: '10px' }}
        />
        </>
        :
        <>
          <Card
            type="course-header"
            data={courseInfo}
            setCurrentOption={setCurrentOption}
            currentOption={currentOption}
            currentUser={currentUser}
          ></Card>
        <div className={styles.optionsList} style={{width: (currentUser?.role === "external_teacher" || 
        currentUser?.role === "internal_teacher" || currentUser?.role === "development_teacher" ||
          currentUser?.role === "external_coordinator") && (isDesktop ? '34.5%' : '100%')}}>
          <Card
          type="classes"
          data="forumData"
          courseId={course?.id}
          setCurrentOption={setCurrentOption}
          currentOption={currentOption}
          nextLesson={course?.next_lesson?.lesson_number}
          ></Card>
          <>
              <Card
              type="forum"
              data={forum}
              courseId={course?.id}
              setCurrentOption={setCurrentOption}
              currentOption={currentOption}
              ></Card>
            <Card
            type="sections"
            data={course?.sections}
            courseId={course?.id}
            setCurrentOption={setCurrentOption}
            currentOption={currentOption}
            ></Card>
          </>
        </div>
        {(course?.inventory || course?.activity_guide ||course?.teacher_book || course?.student_book ||course?.indicators) &&
        <div className={styles.optionsList} style={{width: (currentUser?.role === "external_teacher" || 
          currentUser?.role === "internal_teacher" || currentUser?.role === "development_teacher" ||
            currentUser?.role === "external_coordinator") && (isDesktop ? '34.5%' : '100%')}}>
          <Grid container direction={"row"} columnGap={1} rowGap={1}>
            {course?.inventory && (
              <Grid item xs={5.8} sm={5.7}>
                <Link to={course?.inventory} target="_blank">
                <Button variant="outlined"
                sx={{borderRadius: '10px', 
                  width:'100%', 
                  height:'100%', backgroundColor: '#fff', fontSize:'11px'}}
                startIcon={<i className="fas fa-box-open"></i>}>
                Inventario
                </Button>
                </Link>
              </Grid>
            )}
            {course?.activity_guide && (
              <Grid item xs={5.8} sm={5.7}>
                <Link to={course?.activity_guide} target="_blank">
                <Button variant="outlined"
                sx={{borderRadius: '10px', 
                  width:'100%', 
                  height:'100%', backgroundColor: '#fff', fontSize:'11px'}}
                startIcon={<i className="fal fa-shapes"></i>}>
                Guía de armados
                </Button>
                </Link>
              </Grid>
            )}
            {course?.teacher_book && (
              <Grid item xs={5.8} sm={5.7}>
                <Link to={course?.teacher_book} target="_blank">
                <Button variant="outlined"
                sx={{borderRadius: '10px', 
                  width:'100%', 
                  height:'100%', backgroundColor: '#fff', fontSize:'11px'}}
                startIcon={<i class="fas fa-book"></i>}>
                Libro - Docente
                </Button>
                </Link>
              </Grid>
            )}
            {course?.student_book && (
              <Grid item xs={5.8} sm={5.7}>
                 <Link to={course?.student_book} target="_blank">
                <Button variant="outlined" 
                sx={{borderRadius: '10px', 
                  width:'100%', 
                  height:'100%', backgroundColor: '#fff', fontSize:'11px'}}
                startIcon={<i class="fas fa-book"></i>}>
                Libro - Alumnos
                </Button>
                </Link>
              </Grid>
            )}
            {course?.indicators && (
              <Grid item xs={5.8} sm={5.7}>
                <Link to={course?.indicators} target="_blank">
                <Button variant="outlined" 
                sx={{borderRadius: '10px', 
                  width:'100%', 
                  height:'100%', backgroundColor: '#fff',fontSize:'11px' }}
                startIcon={<i class="fas fa-list-ul"></i>}>
                Indicadores
                </Button>
                </Link>
              </Grid>
            )}
            {course?.preschool_handbook && (
              <Grid item xs={5.8} sm={5.7}>
                <Link to={course?.preschool_handbook} target="_blank">
                <Button variant="outlined" 
                sx={{borderRadius: '10px', 
                  width:'100%', 
                  height:'100%', backgroundColor: '#fff',fontSize:'11px' }}
                startIcon={<i class="fas fa-list-ul"></i>}>
                Manual de preescolar
                </Button>
                </Link>
              </Grid>
            )}


          </Grid>
        </div>}
        {currentUser?.role !== "external_teacher" && currentUser?.role !== "internal_teacher" &&
          currentUser?.role !== "development_teacher" &&
          currentUser?.role !== "external_coordinator" && (teachers?.length > 0) && (
            <div className={styles.optionsList}>
              {teachers?.map((teacher) => (
                <Link
                  to={`/profile/${teacher?.id}`}
                  style={{ textDecoration: "none", color: "#000" }}
                  key={teacher?.id}
                >
                  <Card
                    type="teacher"
                    data={teacher}
                    courseId={course?.id}
                    setCurrentOption={setCurrentOption}
                    currentOption={currentOption}
                    key={teacher?.id}
                  ></Card>
                </Link>
              ))}
            </div>
          )}
        </>
         }
      </div>
      {course?.program_versions?.length > 1 &&
      <Box sx={{width:'100%', display:'flex', alignItems:'center'}}>
        <p style={{fontSize: '16px'}}>Versiones</p>
        <Tooltip 
        arrow
        sx={{marginRight: '10px',marginLeft: '2px'}}
        componentsProps={{
          tooltip: {
            sx: {
              fontSize: '1.2rem',
            },
          },
        }}
        title={<div>
          {`Para este curso existen varias versiones de clases, 
        por favor selecciona que versión deseas ver`}
        {versions?.map((item) => (
          <p>{`Versión ${item?.number}: ${item?.age}`}</p>
        ))}
        </div>}
        >
          <IconButton size="small">
          <InfoIcon sx={{color: 'var(--main-blue)'}} />
          </IconButton>
        </Tooltip>
        <Box
            sx={{
              display: 'flex',
              gap: 1,
              flexWrap: 'wrap',
              flexGrow: 1,
            }}
          >
            {course?.program_versions?.map((version) => {
              const versionAge = versions?.find((item) => item.number === version)
            return(
              <Tooltip 
              arrow
              sx={{marginRight: '10px',marginLeft: '2px'}}
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: '1.2rem',
                  },
                },
              }}
              title={versionAge?.age}
              >
                <Button
                  key={version}
                  variant={currentVersion === version ? 'contained' : 'outlined'}
                  color="primary"
                  onClick={() => handleChangeActiveVersion(version)}
                  sx={{
                    borderRadius: 2,
                    textTransform: 'none',
                    fontSize: '1rem',
                    '&:hover': {
                    },
                  }}
                >
                  {version}
                </Button>
                </Tooltip>
              )})}
          </Box>

      </Box>}
      <div className={styles.rightside}>
        <Outlet
          context={{
            forum: [forum],
            sections: [course?.sections],
            getCourse: [getCourse],
            currentVersion: [currentVersion],
          }}
        />
      </div>
    </div>
    </>
  );
};

export default CoursePage;
