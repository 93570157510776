import { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  LS_USER_KEY,
  LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  USERS_PROFILE_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
  CONFIRM_PASSWORD_ENDPOINT,
  USERS_ACCEPT_AGREEMENT_ENDPOINT,
} from "../utils/constants";
import useLocalStorage from "../hooks/useLocalStorage";
import { get, post } from "../utils/http";
import { AlertsContext } from "./AlertsContext";

const CurrentUserContext = createContext();

const CurrentUserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useLocalStorage("user", "");
  const showSnackbar = useContext(AlertsContext);
  const [loading, setLoading] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const navigate = useNavigate();

  const loginUser = async ({ username, password }) => {
    const url = LOGIN_ENDPOINT;
    const data = { username, password };

    const response = await post({ url, data });

    if (!response.error) {
      showSnackbar("Sesión iniciada", "success");
      const tokens = {
        refresh: response.data.refresh,
        access: response.data.access,
      };
      const userId = response.data.id;

      const config = { headers: { Authorization: `Bearer ${tokens.access}` } };

      localStorage.setItem(LS_USER_KEY, JSON.stringify(tokens));

      const resp = await fetchProfile(config);
      if (!resp.error) {
        setCurrentUser({ tokens, ...resp.data });
        setValidToken(true);
        navigate("/");
        return { error: null };
      }
    } else {
      if (response.error === 401) {
        showSnackbar("Credenciales inválidas. Prueba reestabler tu contraseña", "error");
      } else if (response.error === 403){
        showSnackbar("Ha ocurrido un error. Si el error persiste por favor contacta a soporte.", "error");
      }
      else {
        showSnackbar("Ha ocurrido un error", "error");
      }
      return { error: "Ha ocurrido un error" };
    }
  };

  const fetchProfile = async (config, userId) => {
    const url = `${USERS_PROFILE_ENDPOINT}`;

    const requestObject = config ? { url, config } : { url };

    const response = await get(requestObject);
    const data = response.data;
    if (!response.error) {
      return { error: null, data };
    }
  };

  const resetPassword = async (data) => {
    const url = RESET_PASSWORD_ENDPOINT;
    const response = await post({ url, data });

    if (!response.error) {
      showSnackbar(
        "En breve recibira las instrucciones para cambio de clave en su correo electrónico",
        "success"
      );
    } else {
      showSnackbar("No se encontro la dirección proporcionada", "error");
    }
  };

  const acceptAgreement = async (data) => {
    const url = USERS_ACCEPT_AGREEMENT_ENDPOINT;
    const response = await post({ url, data });
    if (!response.error) {
      //Actualiza el campo en el localStorage con el nuevo valor
      const updatedUser = {
        ...currentUser, 
        teacher_profile: {
          ...currentUser.teacher_profile,
          nda_agreement: true, 
        },
      };
      setCurrentUser(updatedUser); // Actualiza el estado del usuario en el contexto
      showSnackbar("Acuerdo aceptado", "success");
    }
     else {
      showSnackbar("Hubo un error", "error");
    }
  };
    
  const confirmPasswordChange = async ({ password, token }) => {
    const url = `${CONFIRM_PASSWORD_ENDPOINT}`;
    const data = { password, token };
    const response = await post({ url, data });

    if (!response.error) {
      showSnackbar("Constraseña actualizada correctamente", "success");
      navigate("/");
    } else {
      showSnackbar("Hubo un error al cambiar su contraseña", "error");
    }
  };

  const logoutUser = async () => {
    const url = LOGOUT_ENDPOINT;

    const data = { all: 1, refresh: currentUser.tokens.refresh };

    const response = await post({ url, data });

    setCurrentUser(null);
    localStorage.clear();
    localStorage.removeItem(LS_USER_KEY);
    setValidToken(false);

    if (!response.error) {
      showSnackbar("Se ha cerrado sesión", "alert");
      navigate("/login");
    } else {
      navigate("/login");
      showSnackbar("Su sesión expiro, inicie sesión nuevamente", "alert");
    }
  };

  const contextData = {
    currentUser,
    setCurrentUser,
    useCurrentUser,
    loginUser,
    fetchProfile,
    logoutUser,
    resetPassword,
    confirmPasswordChange,
    validToken,
    setValidToken,
    acceptAgreement,
  };

  // Whenever authTokens & state of loading changes. User state changes

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <CurrentUserContext.Provider value={contextData}>
      {loading ? null : children}
    </CurrentUserContext.Provider>
  );
};

const useCurrentUser = () => {
  return useContext(CurrentUserContext);
};

export { CurrentUserContext, CurrentUserProvider, useCurrentUser };
