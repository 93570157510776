import React from 'react'
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import ListCapacitaciones from './ListCapacitaciones'
import CapacitacionesAnualesList from './CapacitacionesAnualesList'
import Container from '../../Container';

function CapacitacionesMain() {
    const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container>
        <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
        <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs onChange={handleChange} aria-label="visible arrows tabs example" variant="scrollable"
         value={value} >
            {/* aqui estaba el label "Capacitaciones Mensuales" */}
            <Tab label="Formación anual" value="1" sx={{fontSize: '12px'}}/>
            <Tab label="Formaciones mensuales" value="2"  sx={{fontSize: '12px'}}/> 
          </Tabs>
        </Box>
        <TabPanel value="1" sx={{borderRadius: '10px', padding: "10px 2px"}}>
            <CapacitacionesAnualesList />
        </TabPanel>
        <TabPanel value="2" sx={{borderRadius: '10px', padding: "10px 2px"}}>
            <ListCapacitaciones />
        </TabPanel>
        
    </TabContext>
    </div>
    </Container>
  )
}

export default CapacitacionesMain
