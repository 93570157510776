import React, { useState, useEffect }  from 'react';
import ModalGift from '../ModalGift';
import  styles  from './MembershipModal.module.css';

const MembershipModal = ({ openMembership, setOpenMembership }) => {

    useEffect(() => {
      }, [openMembership]);
      
    const handleCancel = () => {
        setOpenMembership(false);
      };
      
  return (
    <ModalGift title={"Kurios Membership"} open={openMembership} modalOpen={setOpenMembership} onClose={handleCancel}>
        <div className={styles['image-container']}>
                <img src="https://kuriosedu.s3.us-east-2.amazonaws.com/media/Ventana+emergente+intriga+plataforma+Kurios+-+Membership.gif"
                alt="Membership Intriga" />
        </div> 
    </ModalGift>
  );
};

export default MembershipModal;
